exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-about-managements-tsx": () => import("./../../../src/pages/about/managements.tsx" /* webpackChunkName: "component---src-pages-about-managements-tsx" */),
  "component---src-pages-about-message-from-cmd-tsx": () => import("./../../../src/pages/about/message-from-cmd.tsx" /* webpackChunkName: "component---src-pages-about-message-from-cmd-tsx" */),
  "component---src-pages-about-quality-standards-and-infrastructure-tsx": () => import("./../../../src/pages/about/quality-standards-and-infrastructure.tsx" /* webpackChunkName: "component---src-pages-about-quality-standards-and-infrastructure-tsx" */),
  "component---src-pages-about-social-responsibility-tsx": () => import("./../../../src/pages/about/social-responsibility.tsx" /* webpackChunkName: "component---src-pages-about-social-responsibility-tsx" */),
  "component---src-pages-author-tsx": () => import("./../../../src/pages/author.tsx" /* webpackChunkName: "component---src-pages-author-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-guides-tsx": () => import("./../../../src/pages/guides.tsx" /* webpackChunkName: "component---src-pages-guides-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industries-services-tsx": () => import("./../../../src/pages/industries/services.tsx" /* webpackChunkName: "component---src-pages-industries-services-tsx" */),
  "component---src-pages-order-and-delivery-terms-tsx": () => import("./../../../src/pages/order-and-delivery-terms.tsx" /* webpackChunkName: "component---src-pages-order-and-delivery-terms-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-sales-and-services-index-tsx": () => import("./../../../src/pages/sales-and-services/index.tsx" /* webpackChunkName: "component---src-pages-sales-and-services-index-tsx" */),
  "component---src-pages-ssstatic-location-index-tsx": () => import("./../../../src/pages/ssstatic-location/index.tsx" /* webpackChunkName: "component---src-pages-ssstatic-location-index-tsx" */),
  "component---src-pages-technical-press-news-tsx": () => import("./../../../src/pages/technical-press-news.tsx" /* webpackChunkName: "component---src-pages-technical-press-news-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-thank-you-index-tsx": () => import("./../../../src/pages/thank-you/index.tsx" /* webpackChunkName: "component---src-pages-thank-you-index-tsx" */),
  "component---src-pages-whitepaper-tsx": () => import("./../../../src/pages/whitepaper.tsx" /* webpackChunkName: "component---src-pages-whitepaper-tsx" */),
  "component---src-templates-accessories-index-tsx": () => import("./../../../src/templates/accessories/index.tsx" /* webpackChunkName: "component---src-templates-accessories-index-tsx" */),
  "component---src-templates-accuracy-cube-index-tsx": () => import("./../../../src/templates/accuracy-cube/index.tsx" /* webpackChunkName: "component---src-templates-accuracy-cube-index-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/blog/index.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-blogs-index-tsx": () => import("./../../../src/templates/blogs/index.tsx" /* webpackChunkName: "component---src-templates-blogs-index-tsx" */),
  "component---src-templates-case-study-index-tsx": () => import("./../../../src/templates/case-study/index.tsx" /* webpackChunkName: "component---src-templates-case-study-index-tsx" */),
  "component---src-templates-city-index-tsx": () => import("./../../../src/templates/city/index.tsx" /* webpackChunkName: "component---src-templates-city-index-tsx" */),
  "component---src-templates-events-index-tsx": () => import("./../../../src/templates/events/index.tsx" /* webpackChunkName: "component---src-templates-events-index-tsx" */),
  "component---src-templates-guide-index-tsx": () => import("./../../../src/templates/guide/index.tsx" /* webpackChunkName: "component---src-templates-guide-index-tsx" */),
  "component---src-templates-industries-index-tsx": () => import("./../../../src/templates/industries/index.tsx" /* webpackChunkName: "component---src-templates-industries-index-tsx" */),
  "component---src-templates-industry-service-index-tsx": () => import("./../../../src/templates/industry-service/index.tsx" /* webpackChunkName: "component---src-templates-industry-service-index-tsx" */),
  "component---src-templates-product-detail-index-tsx": () => import("./../../../src/templates/product/detail/index.tsx" /* webpackChunkName: "component---src-templates-product-detail-index-tsx" */),
  "component---src-templates-product-listing-index-tsx": () => import("./../../../src/templates/product/listing/index.tsx" /* webpackChunkName: "component---src-templates-product-listing-index-tsx" */),
  "component---src-templates-technical-press-index-tsx": () => import("./../../../src/templates/technical-press/index.tsx" /* webpackChunkName: "component---src-templates-technical-press-index-tsx" */)
}

